// interface BillingConfig {
//     title: string,  // 大类标题
//     type: string,   // 类型 radio: 互斥选项 checkbox: 非互斥选项
//     code: string,    // 配置对应的键,
//     name?: string,   // 配置名称
//     remark?: string,    // 配置描述
//     items: configItem[]    // 配置包含的子明细
// }

// interface configItem {
//     label: string,  // 勾选标题
//     checked: boolean,   // 是否默认勾选
//     disabled: boolean,  // 是否不可勾选
//     msg?: string,   // 配置相关描述
//     rules: string[] // 包含配置规则
// }

export const BILLING_CONFIGS = [
  {
    title: '拆分规则配置',
    type: 'radio',
    code: 'BILL_O2I_MERGE_RULE',
    name: '销项开票-原始订单合并规则',
    remark: '租户:销项开票-原始订单合并规则',
    items: [{ label: '按照当前组织默认开票设备限额拆分', checked: true, disabled: true, rules: [] }]
  },
  {
    title: '发票分组规则',
    type: 'checkbox',
    code: 'BILL_O2I_MERGE_RULE',
    name: '销项开票-原始订单合并规则',
    remark: '租户:销项开票-原始订单合并规则',
    items: [
      { label: '同销售方信息', checked: true, disabled: true, msg: '同名称、税号及账号的销方明细会合并为一张待开发票', rules: ['SELLER_NAME', 'SELLER_TAX_NO'] },
      { label: '同购买方信息', checked: true, disabled: true, msg: '同名称、税号、地址电话、开户行及账号的购方明细会合并为一张待开发票', rules: ['BUYER_NAME', 'BUYER_TAX_NO'] },
      { label: '同发票类型', checked: true, disabled: true, msg: '同发票类型的订单明细会合并为一张待开发票', rules: ['BILLING_TYPE'] },
      { label: '同业务单号', checked: false, disabled: false, msg: '同业务单号的订单明细会合并为一张待开发票', rules: ['FIELD_TRADE_CODE'] },
      { label: '同发票税率', checked: false, disabled: false, msg: '同发票税率的订单明细会合并为一张待开发票', rules: ['MERCHANDISE_TAX_RATE'] }
    ]
  },
  {
    title: '明细合并规则',
    type: 'radio',
    code: 'BILL_O2I_MX_MERGE_RULE',
    name: '销项开票-原始订单合并规则',
    remark: '租户:销项开票-原始订单合并规则',
    items: [
      {
        label: '同商品中类',
        checked: false,
        disabled: false,
        msg: '同中类名称的原始信息会合并为一张待开发票/一条商品明细开票',
        rules: ['MERCHANDISE_MIDDLE_KIND']
      },
      {
        label: '同商品名称',
        checked: true,
        disabled: false,
        msg: '同商品名称的原始信息会合并为一张待开发票/一条商品明细开票',
        rules: ['MERCHANDISE_NAME', 'MERCHANDISE_SPECIFICATION', 'MERCHANDISE_UNIT', 'MERCHANDISE_TAX_RATE']
      },
      {
        label: '同商品编码',
        checked: false,
        disabled: false,
        msg: '同商品编码的原始信息会合并为一张待开发票/一条商品明细开票',
        rules: ['UNIQUE_CODE']
      }
    ]
  },
  {
    title: '开票抬头配置',
    type: 'checkbox',
    code: 'MANUAL_SEARCH_BUYER',
    name: '手工开票-模糊匹配抬头',
    items: [
      {
        label: '云端发票抬头',
        checked: true,
        disabled: true,
        msg: '在销项开票-手工开票方面购买方名称中录入抬头信息后,系统自动根据配置的抬头条件,在‘云端发票抬头库’进行发票抬头数据检索',
        rules: ['REMOTE_TITLE']
      },
      {
        label: '本地客户信息',
        checked: false,
        disabled: false,
        msg: '在销项开票-手工开票页面购买方名称中录入抬头信息后,系统自动根据配置的抬头条件,在‘本地客户信息表’进行发票抬头数据检索',
        rules: ['BASE_TITLE']
      },
      {
        label: '客户名称',
        checked: true,
        disabled: true,
        msg: '支持通过云端发票抬头库、本地客户信息表根据‘客户名称’模糊检索发票抬头信息',
        rules: ['TITLE_NAME']
      },
      {
        label: '客户编号',
        checked: false,
        disabled: false,
        msg: '支持通过本地客户信息表根据‘客户编号’模糊检索发票抬头信息',
        rules: ['TITLE_UNIQUE_CODE']
      },
      {
        label: '纳税人识别号',
        checked: false,
        disabled: false,
        msg: '支持通过云端发票抬头库、本地客户信息表根据‘纳税人识别号’精准检索发票抬头信息',
        rules: ['TITLE_TAX_NO']
      }
    ]
  },
  {
    title: '其他规则',
    type: 'checkbox',
    code: 'OFFSET_NEGATIVE_PERCENT',
    name: '销项开票-原始订单合并规则',
    // remark: '租户:销项开票-原始订单合并规则',
    items: [
      {
        label: '负数行明细冲抵',
        checked: true,
        disabled: true,
        msg: '同一订单存在多个商品时，商品中有负数的商品，使用该功能使正数商品与负数商品抵扣合并',
        rules: ['Y']
      }
    ]
  }
];

export const OPENER_SETTING = [
    {
        title: "开票人配置",
        type: "checkbox",
        code: "BILL_OPENER_RULE",
        name: "开票人配置",
        items: [
            {
                label: "当前用户",
                label2: '指定用户',
                radio: '当前用户',
                disabled: false,
                name: '',
                msg: "当前开票方式不支持自定义开票人;处理业务时,若当前用户未指定数电账号,则优先使用默认账号开票",
                rules: ["CURRENT_USER"]
            },
        ]
    }
];
